.customers {
  display: inline-flex;
}

.customers-completed {
  text-decoration: line-through;
}

.customers-tab-content {
  overflow-y: scroll;
}

.customers-container {
  padding: 20px;
  height: 100vh;
}

.customers-container-hidden {
  padding: 20px;
  height: 100vh;
  overflow: hidden;
}

.customers-page-header {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}

.customers-page-actions {
  position: absolute;
  right: 20px;
  padding: 20px;
}

.disabled-link {
  pointer-events: none;
}

.no-padding {
  padding: 0 !important;
}

.action-button {
  color: #1792e5 !important;
  font-size: 14px;
  cursor: pointer;
}

.action-button:hover {
  color: #1792e5;
}

.cursor-pointer {
  cursor: pointer;
}

.offering-badge {
  // word-wrap: break-word;
  padding: 5px 15px 4px;
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  line-height: 18px;
  color: #000;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #606060;
  border-radius: 32px;
  margin-left: 3px;
  margin-bottom: 3px;
}

.add-customer {
  height: 17px;
  width: 116px;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  vertical-align: super;
  cursor: pointer;
}

.appsbadge {
  height: 22px;
  width: 35px;
  border-radius: 11px;
  background-color: #1792e5;
  // margin-right: 50px;
}

.appsbadge-value {
  height: 18px;
  width: 14px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.edit-label {
  background-color: #1792e5 !important;
  color: #ffffff !important;
}

.customer-count {
  float: right;
  display: flex;
  margin-top: 7px;
}

.search-box-wrap {
  overflow: hidden;
  display: flex;
}

.input-search {
  float: right;
  padding-bottom: 12px;
  margin-left: auto;
}

.table-head {
  padding-bottom: 10px;
  display: flow-root;
}

.add-cutomer-btn {
  display: initial;
}

// Inner SCUF CSS Do not delete or manipulate
.total-count {
  display: none !important;
}

.filter-bar {
  margin-left: auto;
  margin-right: 10px;
}

.ui-datatable-header {
  border-color: transparent !important;
  background: transparent !important;
}

.input-box,
.search-icon {
  height: 2.5rem !important;
}
