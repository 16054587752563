.modal-class {
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
}

.modal-header {
  height: 50px !important;
  background-color: #404040 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

.new-customer {
  color: #ffffff !important;
  font-size: 18px !important;
  margin-left: 20px !important;
  margin-top: 5px !important;
}

.div-input {
  float: left !important;
  width: 23%;
}

.close-icon-wrap {
  top: 10px !important;
}

.modal-hr {
  border-bottom: 0.5px #97979786 solid !important;
}

.common-body-footer-padding {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.no-padding {
  padding: 0 !important;
}

.ui.scuf-grid {
  margin: 0 !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.wrapper {
  display: flex;
}

.style-typography-h-1-dark {
  height: 68px !important;
  width: 297px !important;
}

.page-title {
  height: 36px;
  width: 289px;
  color: #404040;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

.rectangle {
  height: 212px;
  width: 330px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
}

.group-2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 300px;
}

.uploadImageClass {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 300px;
  height: 118px;
}

.rectangle-25 {
  background-color: #ffffff;
}

.create-customer-buttons {
  text-align: right;
}

.ui.modal .actions {
  background-color: #ffffff !important;
  padding-bottom: 20px !important;
}

.offering-check-box>label {
  font-size: 16px !important;
  letter-spacing: 0.25px !important;
}

.offering-check-box {
  margin-top: -8px !important;
}

.offering-header-alignment {
  padding: 15px 15px 0 15px !important;
}

.offering-header-alignment>.content>.header {
  font-weight: 600 !important;
}

.offering-header-first-item {
  border: 0.5px solid #97979786;
}

.offering-header-rest-item {
  border-right: 0.5px solid #97979786;
  border-left: 0.5px solid #97979786;
  border-bottom: 0.5px solid #97979786;
}

.offering-header-rest-item>.content {
  background-color: transparent !important;
}

.scuf-list-wrapper {
  margin: 0 !important;
}

.modal-body {
  height: calc(100% - 8.5rem);
  overflow-y: auto;
}

.modal-footer {
  padding-top: 20px !important;
  border-top: 1px solid #97979786 !important;
}

.ui.single-input .input-box {
  width: 300px !important;
  max-width: 22rem !important;
}

.organizationLogoPreviewClass {
  border: 1px solid #d0d0d0;
  background-color: #efefef;
  height: 220px;
  width: 300px;
  text-align: center;
}

.organizationLogoPreviewClass>img {
  object-fit: contain;
  max-width: 98%;
  max-height: 98%;
}

.col-distance {
  margin-top: 20px !important;
}

.no-overflow {
  overflow: hidden;
}

.currency-alert-icon {
  color: #F5A623 !important;
}

.currency-alert-message {
  height: 18px;
  width: 161px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.currency-select {
  height: 48px;
  width: 300px !important;
}

.currency-error-modal {
  z-index: 1001;
}

.currency-checkbox {
  margin-top: 11px !important;
}

.modal-subtitle {
  margin-bottom: 14px !important;
}

// Internal SCUF css do not delete
.input-label-message {
  margin-right: 0px !important;
}

.scuf-col.scuf-grid.col-12 {
  height:95px !important;
}

.modal-body.scrolling.content {
  overflow-x: hidden !important;
  max-height: unset !important;
}
